import { useTranslation } from 'react-i18next';
import notFoundImage from '../../../../ui/src/assets/images/img-404.webp';
import serverErrorImage from '../../../../ui/src/assets/images/img-500.webp';

import type { ErrorResponse } from '@remix-run/router';

import styles from 'app/assets/styles/error.css';
export const links = () => [{ rel: 'stylesheet', href: styles }];

const consoleMessage =
'console.log("💿 Hey developer👋. You can provide a way better UX than this when your app throws errors. Check out https://remix.run/guides/errors for more information.")';

const handleClickReload = `(function () {
  function handleDomReady() {
    const button = document.querySelector('.js-reload-button');
    if (!button) return;
    button.addEventListener('click', () => window.location.reload());
  }
  document.addEventListener('DOMContentLoaded', handleDomReady);
})()`;

type Props = {
  caught?: ErrorResponse;
  error?: Error;
  status?: number;
};

export const ErrorPage = ({ caught, error, status }: Props) => {
  const { t } = useTranslation();

  const notFoundError = status === 404 || caught?.status === 404;
  const { heading, description, imageSrc } = notFoundError ?
  {
    heading: t('errors.notFound.heading'),
    description: t('errors.notFound.description'),
    imageSrc: notFoundImage
  } :
  {
    heading: t('errors.unexpected.heading'),
    description: t('errors.unexpected.description'),
    imageSrc: serverErrorImage
  };
  const pageTitle = notFoundError ?
  `Error 404: ${heading}` :
  `Error 500: ${heading}`;

  if (process.env.NODE_ENV === 'development' && !!error) {
    console.log(error);
  }

  return (
    <html lang="ja">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width" />
        <title>{pageTitle}</title>
        <link rel="stylesheet" href={styles} />
      </head>
      <body>
        <div className="ErrorPage">
          <div className="ErrorPage-body">
            <h1 className="ErrorPage-title">{heading}</h1>
            <p className="ErrorPage-description">{description}</p>
            {process.env.NODE_ENV === 'development' && error?.stack &&
            <div className="ErrorPage-debug">
                <pre className="ErrorPage-code">{error.stack}</pre>
                <script dangerouslySetInnerHTML={{ __html: consoleMessage }} />
              </div>}

            {notFoundError &&
            <p>
                <span className="ErrorPage-status">
                  {status || caught?.status}
                </span>
                <span className="ErrorPage-errorName">Page not found</span>
              </p>}

            <p className="ErrorPage-image">
              <img
                alt=""
                role="presentation"
                src={imageSrc}
                width={179}
                height={180}
                loading="lazy" />

            </p>
            <p className="ErrorPage-action">
              {notFoundError ?
              <a href="/" className="button button--secondary">
                  {t('errors.actions.home')}
                </a> :

              <>
                  <button
                  type="button"
                  onClick={() => window.location.reload()}
                  className="button button--primary js-reload-button">

                    {t('errors.actions.reload')}
                  </button>
                  <a href="/logout" className="button button--secondary">
                    {t('action.logout')}
                  </a>
                </>}

            </p>
          </div>
        </div>
        <script dangerouslySetInnerHTML={{ __html: handleClickReload }} />
      </body>
    </html>);

};